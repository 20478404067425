import request from './request'

const api = {
	// 获取列表
	lists: '/partymembergoals/index',
	// 获取党员列表
	dlist: '/partymember/lists',
	// 添加
	add: '/partymembergoals/add',
	// 删除
	dele: '/partymembergoals/delete',
	// 编辑
	edit: '/partymembergoals/update',
	// 详情
	info: '/partymembergoals/read',
	// 村
	cunlist: '/village/lists',
	// 增加目标积分
	incPoints: '/partymembergoals/incPoints',
	// 减少目标积分
	decPoints: '/partymembergoals/decPoints',
	// 批量设置目标处理时间
	batchProcessTime: '/partymembergoals/batchProcessTime',
}

export {
	request,
	api
}
