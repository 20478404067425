<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['feedbackdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="待处理" :value="0"></el-option>
						<el-option label="已完成" :value="1"></el-option>
					</el-select>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.name" placeholder="请输入姓名">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.phone" placeholder="请输入手机号码">
					</el-input>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="name" label="姓名" />
				<el-table-column property="phone" label="手机号码" />
				<el-table-column property="address" label="位置" />
				<el-table-column property="content" label="反馈问题">
					<template #default="scope">
						<el-popover placement="top-start" title=" " :width="200" trigger="hover"
							:content="scope.row.content">
							<template #reference>
								<div class="pubcontent">{{scope.row.content}}</div>
							</template>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column property="name" label="随时拍图片">
					<template #default="scope">
						<div class="dialog-up">
							<div v-if="scope.row.pics" style="cursor: pointer;" @click="seeImg(ImgList(scope.row.pics))"
								v-for="(item,index) in ImgList(scope.row.pics).slice(0,1)" :key="index">
								<el-image style="width: 80px; height: 80px;" :src="item" fit="fill">
								</el-image>
								<p>共{{ImgList(scope.row.pics).length}}张点击查看</p>
							</div>
							<div class="zanwu" v-else>暂无</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column property="create_time" label="状态">
					<template #default="scope">
						<span v-if="scope.row.status==0">待处理</span>
						<span v-if="scope.row.status==1">已完成</span>
					</template>
				</el-table-column>
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="200">
					<template #default="scope">
						<el-button v-permission="['feedbacksee']"
							@click="seeMap(scope.row.address,scope.row.lat,scope.row.lng)" size="mini">地图位置</el-button>
						<el-button v-permission="['feedbackdele']" size="mini" @click="dele(scope.row.order_sn)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 全部图片 -->
		<seeImg ref="seeImg"></seeImg>
		<!-- 地图 -->
		<seeMap ref="seeMap"></seeMap>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/feedback";
	import seeMap from './seeMap.vue'
	import seeImg from '@/components/seeImg.vue'
	export default {
		components: {
			seeImg,
			seeMap
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					status: '',
					phone: '',
					name: '',
					page: 1,
					limit: 15,
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			if(this.$route.query.state) {
				this.form.status = Number(this.$route.query.state)
				this.getList()
			}
			this.getList()
		},
		methods: {
			// 查看地图
			seeMap(address, lat, lng) {
				this.$refs.seeMap.show(address, lat, lng)
			},
			// 查看图片
			seeImg(list) {
				this.$refs.seeImg.show(list)
			},
			// 图片转化
			ImgList(val) {
				if (val) {
					return val.split(",")
				} else {
					return []
				}
			},
			// 新增
			bindadd(val, id) {
				this.$refs.add.show(val, id)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.is_duizhang = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									order_sn: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(order_sn) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								order_sn: order_sn
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
	.dialog-up {
		justify-content: center;
	}
</style>
