import request from './request'

const api = {
	// 获取列表
	lists: '/zt/Order/list',
	// 删除
	dele: '/zt/Order/delete',
	// 详情
	info: '/zt/Order/read',
}

export {
	request,
	api
}
